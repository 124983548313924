$font-size-xs: 0.7em;
$font-size-sm: 0.75rem;
$font-size: 0.8rem;
$font-size-md: 1.02rem;
$font-size-lg: 1.2rem;

$font-weight: 400;
$font-weight-semibold: 600;
$font-weight-bold: 800;

$primary-color: #0C669A;
$secondary-color: #2e6ac3;
$darker-secondary-color: #4365ae;
$grey-color: #a9a9ab;
$dark-grey-color: #666;
$light-grey-color: #ccc;
$black-color: #1d1e1f;
$white-color: #fff;
$hover-color: #ffffdd;
$danger-color: #ed1c24;

$border-radius: 5px;
$drawer-width: 180px;