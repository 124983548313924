.loggedin {
  display: flex;
  height: 100vh;

  .layout {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--theme-layout-color);


    @media only screen and (min-width: 640px) {
      margin-left: $drawer-width;
    }
  }

  .ag-header-cell-text {
    text-transform: capitalize !important;
  }

  .ag-cell-wrap-text {
    word-break: break-word !important;
  }

  .filter-dropdown {
    height: 300px;
    overflow: auto;
    background-color: var(--theme-drawer-color);
    border-radius: $border-radius;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    .uk-nav.uk-dropdown-nav li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .select-filter-button {
    text-transform: none;
    border-radius: $border-radius;
  }

  .filter-text-button {
    text-transform: none;
    border-radius: $border-radius;
    color: var(--theme-text-color);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    height: 40px;
    width: 300px;
    text-align: left;
    transition: all .5s;

    &:hover {
      background-color: $darker-secondary-color;
      color: $white-color ;
    }
  }

  .filter-delete-button {
    background-color: $light-grey-color;
    border-radius: $border-radius;
    margin: 3px;
    padding: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    transition: all .5s;

    .icon {
      position: relative;
      right: 0;

      &.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
        stroke: red;
      }
    }

    &:hover {
      background-color: $danger-color;

      .uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
        stroke: $white-color;
      }

    }
  }

  .licenses {
    padding: 20px 20px;
    width: 100%;

    .licences-card {
      background-color: var(--theme-card-color);

      .total {
        font-weight: 500;
        color: #181f1d;
        font-size: 14px;
      }
    }

    .link-container {
      position: relative;
      display: inline-block;

      .link-content {
        .copy-icon {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s, opacity 0.5s ease;

          svg,
          polyline,
          rect {
            color: $darker-secondary-color;
          }
        }
      }

      &:hover .copy-icon,
      .copy-icon:hover {
        cursor: pointer;
        margin-right: 3px;
        visibility: visible;
        opacity: 1;
      }
    }

    .ag-column-panel {
      max-height: 100vh !important;
    }

    .ag-body-vertical-scroll-container {
      height: 10px;
      width: 10px !important;
      max-width: 10px !important;
      min-width: 10px !important;
    }

    .ag-body-vertical-scroll {
      width: 10px !important;
      max-width: 10px !important;
      min-width: 10px !important;
    }

    .ag-body-horizontal-scroll-viewport {
      height: 10px !important;
      max-height: 10px !important;
      min-height: 10px !important;
    }
  }

  .queries {
    padding: 20px 20px;
    width: 100%;

    .queries-card {
      background-color: var(--theme-card-color);

      .query {
        border-radius: $border-radius;
        cursor: pointer;
        transition: background-color 0.5s ease;
        background-color: var(--theme-drawer-color);
        border: 3px solid transparent;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

        p,
        h6 {
          color: var(--theme-text-color);
        }

        &:hover {
          border: 3px solid var(--theme-primary-color);
        }

        &.active {
          border: 3px solid var(--theme-primary-color);
        }
      }
    }
  }
}