.login {
  background-image: url("../components/images/Image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  .logged-out-navbar {
    background-color: $white-color;
    height: 100px;

    .logged-out-logo {
      max-height: 40px;
      width: auto;
      margin: 10px;
    }
  }

  .login-card {
    background: #F0F0F0;
    margin: 240px auto 0;
    padding: 3em;
    border-radius: 10px;
    box-sizing: border-box;

    .uk-card-title {
      font-size: 20px;
      line-height: 120%;
    }
  }
}