.single-select {
    min-width: 250px;
    border: 0;


    .select__control {
        height: 40px;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        width: 300px; //

        &.select__control--is-focused {
            border-color: $primary-color !important;
            box-shadow: none;
        }
    }

    .select__option {
        padding: 1px;
        margin: 5px 0px;
        background-color: $darker-secondary-color;
        border-radius: 5px;
        color: $white-color;


        &:hover,
        &:focus,
        &:active {
            background-color: #8f8f8f;
            cursor: pointer;
        }

        &--is-disabled {
            margin-top: 5px;
            color: #172b4d !important;
            background-color: transparent !important;
        }
    }
}

#hidden-input {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;

    input {
        width: 0;
        height: 0;
    }
}